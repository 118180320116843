import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import { col1, col2, col3 } from './data/FooterData'

const FooterComponent = dynamic(() => import('../components/Footer'))

const FooterContainer = () => (
  <FooterComponent
    col1={col1}
    col2={col2}
    col3={col3}
  />
)

export default FooterContainer
