const col1: FooterGlobal.FooterItem[] = [
  {
    name: 'Chi siamo',
    title: 'Chi siamo',
    href: '/chi-siamo',
    target: '_self',
  },
  {
    name: 'Codice etico',
    title: 'Codice etico CarPlanner',
    href: 'https://static1.carplanner.com/website/docs/carplanner-codice-etico.pdf',
    target: '_blank',
  },
  {
    name: 'Auto elettriche',
    title: 'Noleggio auto elettriche a Lungo Termine',
    href: '/auto-elettriche/noleggio-a-lungo-termine',
    target: '_self',
  },
  {
    name: 'Offerte Noleggio a Lungo Termine',
    title: 'Offerte Noleggio a Lungo Termine',
    href: '/noleggio-a-lungo-termine',
    target: '_self',
  },
  {
    name: 'Vantaggi del Noleggio',
    title: 'Vantaggi del Noleggio',
    href: '/noleggio-a-lungo-termine/vantaggi',
    target: '_self',
  },
  {
    name: 'FAQ',
    title: 'FAQ',
    href: '/noleggio-a-lungo-termine/faq',
    target: '_self',
  },
]

const col2: FooterGlobal.FooterItem[] = [
  {
    name: 'Noleggio a Lungo Termine Senza Anticipo',
    title: 'Noleggio a Lungo Termine senza anticipo',
    href: '/noleggio-a-lungo-termine/senza-anticipo',
    target: '_self',
  },
  {
    name: 'Noleggio a Lungo Termine per Privati',
    title: 'Noleggio a Lungo Termine per Privati',
    href: '/noleggio-a-lungo-termine/offerte-privati',
    target: '_self',
  },
  {
    name: 'Noleggio a Lungo Termine per Aziende',
    title: 'Noleggio a Lungo Termine per Aziende',
    href: '/noleggio-a-lungo-termine/offerte-aziende',
    target: '_self',
  },
  {
    name: 'Noleggio a Lungo Termine BMW',
    title: 'Noleggio a Lungo Termine BMW',
    href: '/noleggio-a-lungo-termine/bmw',
    target: '_self',
  },
  {
    name: 'Noleggio a Lungo Termine Mercedes',
    title: 'Noleggio a Lungo Termine Mercedes',
    href: '/noleggio-a-lungo-termine/mercedes',
    target: '_self',
  },
  {
    name: 'Noleggio a Lungo Termine Smart',
    title: 'Noleggio a Lungo Termine Smart',
    href: '/noleggio-a-lungo-termine/smart',
    target: '_self',
  },
]

const col3: FooterGlobal.FooterItem[] = [
  {
    name: 'Cookie Policy',
    title: 'Cookie Policy',
    href: '/policies/cookie',
    target: '_self',
  },
  {
    name: 'Privacy Policy',
    title: 'Privacy Policy',
    href: '/policies/privacy',
    target: '_self',
  },
  {
    name: 'Termini e Condizioni',
    title: 'Termini e Condizioni',
    href: '/policies/terms-and-conditions',
    target: '_self',
  },
  {
    name: 'Mappa del sito',
    title: 'Mappa del sito',
    href: '/mappa-del-sito',
    target: '_self',
  },
]

export { col1, col2, col3 }
